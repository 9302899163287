/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components";

type Props = {
  isDark: boolean
  toggle: (e: any) => void
}

const ToggleContainer = styled.button`
    position: relative;
    font-size: 1rem;
    width: 3.8em;
    height: 2.1em;
    border: 0.125em solid ${props => props.isDark ? "#6B4DC2" : "#F5F5F5"};
    border-radius: 1.5em;
    padding: 0.1em;
    overflow: hidden;
    background: ${props => props.isDark ? "#6B4DC2" : "#6B4DC2"};
    cursor: pointer;
    transition: all 0.3s linear;
    border-left-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-top-width: 2px;
}

    
  `;
  
  const Switch = styled.div`
    width: 1.7em;
    height: 1.6em;
    border-radius: 1.65em;
    background:  ${props => props.isDark ? "#ffffff" : "#ffffff"};
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 3px 0px;
    position: relative;
    transform:  ${props => !props.isDark ? "translateX(0)" : "translateX(1.6em)"};
    transition: inherit;
}

  `;


const ColorModeToggle = ({ isDark, toggle }: Props) => { 
   
  return(

  <ToggleContainer onClick={toggle} isDark={isDark}>
    <Switch isDark={isDark}/>
  </ToggleContainer> )
    
  
}
export default ColorModeToggle