/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import Subscribe from "./subscribe";

const Footer = () => {
  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        color: `secondary`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `column`, `row`],
        variant: `dividers.top`,
      }}
    >
      <div style={{textAlign:"center"}}>
        <label>If you like my content and want to read more of it, Subscribe to my newsletter</label>
        <Subscribe />
        &copy; {new Date().getFullYear()} by{" "}
        <a href="http://manjuladube.dev/">Manjula Subhaschandra Dube</a>. All
        rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
